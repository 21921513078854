import React, { useCallback, useState } from "react";
import AuthContextProvider from "./components/auth/AuthContextProvider";
import Header from "./components/Header";

import config from "./config.json";
import GraphRangeContextProvider from "./components/GraphRangeContextProvider";
import RestartComponent from "./components/RestartComponent";
import Text from "./components/Text";
import Tabs from "./components/Tabs";
import Table from "./components/Table";
import Comments from "./components/Comments";
import DataContextProvider from "./components/DataContextProvider";
import Format from "./components/Format";
import Graphs from "./components/graphs/Graphs";
import InspectionTable from "./components/InspectionTable";

export default function App() {
  const [selectedInspectionTabIndex, setSelectedInspectionTabIndex] =
    useState(0);
  const [selectedInspectionElement, setSelectedInspectionElement] = useState<
    [string, number] | undefined
  >(undefined);

  const onElementSelected = useCallback((val: [string, number] | undefined) => {
    setSelectedInspectionElement(val);
    if (val) setSelectedInspectionTabIndex(1);
  }, []);

  return (
    <div className="text-white talllg:h-screen min-h-screen flex flex-col">
      <AuthContextProvider>
        <RestartComponent />

        <DataContextProvider config={config.dataBlobs}>
          <GraphRangeContextProvider>
            <Header config={config.header} />

            <div className="w-full flex-grow min-h-0 p-2 flex flex-wrap-reverse">
              <div className="w-full lg:w-2/3 lg:pr-1 flex flex-col justify-around h-full space-y-2">
                {[config.line1, config.line2, config.line3].map((line, key) => (
                  <Tabs
                    key={key} // eslint-disable-line react/no-array-index-key
                    className="flex-grow min-h-0 h-1/3"
                  >
                    {(tab) =>
                      line.map((v) =>
                        tab(
                          v.tabName,
                          <div
                            key={v.id}
                            className="h-full w-full flex flex-col"
                          >
                            <h1 className="text-2xl">
                              <Format str={v.title} />

                              <span className="text-sm ml-1">
                                <Format str={v.subtitle} />
                              </span>
                            </h1>

                            {v.graphs && (
                              <Graphs
                                onElementSelected={onElementSelected}
                                graphs={v.graphs}
                              />
                            )}
                          </div>
                        )
                      )
                    }
                  </Tabs>
                ))}
              </div>
              <div className="w-full lg:w-1/3 mb-2 lg:m-0 lg:pl-1 flex flex-col justify-between space-y-2 talllg:h-full">
                <Text config={config.alertText} />

                <Tabs
                  selectedTab={selectedInspectionTabIndex}
                  setSelectedTab={setSelectedInspectionTabIndex}
                  className="min-h-0 flex-grow lg:max-h-[70vh] talllg:max-h-full"
                >
                  {(tab) => [
                    tab(
                      config.metrics.title,
                      <Table key="metrics" config={config.metrics} />
                    ),
                    config.inspectionTab && config.inspectionTab.enabled
                      ? tab(
                          (config.inspectionTab as any).title ??
                            "Inspection Tab",
                          <InspectionTable
                            key="inspection"
                            selected={selectedInspectionElement}
                            config={config.inspectionTab}
                          />
                        )
                      : undefined,
                  ]}
                </Tabs>

                <Tabs className="h-fit">
                  {(tab) =>
                    tab(
                      config.comments.title,
                      <Comments key="comments" config={config.comments} />
                    )
                  }
                </Tabs>
              </div>
            </div>
          </GraphRangeContextProvider>
        </DataContextProvider>
      </AuthContextProvider>
    </div>
  );
}
