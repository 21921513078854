import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import React, { useCallback, useContext, useId, useState } from "react";
import AuthErrorBox from "./AuthErrorBox";
import { authContext, AuthState, cognitoPool } from "./auth";

export default function Login({
  setAuthState,
  setCognitoUser,
}: {
  setAuthState: (state: AuthState) => void;
  setCognitoUser: (cognitoUser: CognitoUser) => void;
}) {
  const { setUserSession } = useContext(authContext)!;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [processing, setProcessing] = useState(false);
  const [authError, setAuthError] = useState("");

  const emailId = useId();
  const passwordId = useId();

  const onSubmit = useCallback(() => {
    setProcessing(true);

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });
    setCognitoUser(cognitoUser);

    cognitoUser.authenticateUser(
      new AuthenticationDetails({
        Username: email,
        Password: password,
      }),
      {
        onSuccess: (session) => {
          setUserSession(session);
          setProcessing(false);
        },
        onFailure: (err: any) => {
          setAuthError(err.message);
          setProcessing(false);
        },
        newPasswordRequired: () => {
          setAuthState(AuthState.NEWPASSWORDREQUIRED);
          setProcessing(false);
        },
      }
    );
  }, [email, password, setAuthState, setCognitoUser, setUserSession]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <h1 className="text-4xl p-5">Sign In</h1>
      {authError && <AuthErrorBox message={authError} />}

      <div className="m-5 text-lg">
        <label htmlFor={emailId}>
          Email
          <input
            type="email"
            id={emailId}
            className="w-full text-black p-2"
            onChange={(ev) => setEmail(ev.target.value)}
            value={email}
            disabled={processing}
          />
        </label>
      </div>

      <div className="m-5 text-lg">
        <label htmlFor={passwordId}>
          Password
          <input
            type="password"
            id={passwordId}
            className="w-full text-black p-2"
            onChange={(ev) => setPassword(ev.target.value)}
            value={password}
            disabled={processing}
          />
        </label>
      </div>

      <div className="w-full flex my-10 flex-col">
        <button
          type="button"
          className="mb-5"
          onClick={() => setAuthState(AuthState.FORGOTPASSWORD)}
        >
          Forgot password?
        </button>

        <button
          type="submit"
          className="bg-gray-300 text-black m-auto px-5 text-lg py-2 rounded-md hover:bg-gray-400 active:bg-gray-500 disabled:bg-neutral-600"
          disabled={!email || !password || processing}
        >
          Submit
        </button>
      </div>
    </form>
  );
}
