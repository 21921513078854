import React, { useContext, useMemo } from "react";
import "chartjs-adapter-moment";
import { ChartData, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import { dataContext } from "../DataContextProvider";

export default function BacktestingGraph({
  config,
}: {
  config: {
    timeSearch?: string;

    datasets: {
      search: string;
      label?: string;
      legend?: boolean;
    }[];
  };
}) {
  const { getNumbersArray, getValue } = useContext(dataContext);

  const data = useMemo(() => {
    const ret: ChartData<"line"> = {
      datasets: config.datasets.map((dataset) => ({
        label: dataset.label,
        data: getValue(dataset.search),
        stepped: "middle",
      })),
    };

    if (config.timeSearch) {
      const rawTimes = getNumbersArray(config.timeSearch);
      if (rawTimes) ret.labels = rawTimes.map((time: number) => time * 1000);
    }

    return ret;
  }, [config.timeSearch, getNumbersArray, config.datasets, getValue]);

  const options: ChartOptions<"line"> = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            color: "rgb(255, 255, 255)",
          },
        },
      },

      scales: {
        x: {
          type: "time",
          time: {
            displayFormats: {
              hour: "HH:mm",
              minute: "HH:mm",
            },
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
            drawOnChartArea: true,
            color: "rgb(50, 50, 50)",
          },
          ticks: {
            maxTicksLimit: 4,
            maxRotation: 0,
          },
        },
        y: {
          grid: {
            drawTicks: false,
            drawBorder: false,
            drawOnChartArea: true,
            color: "rgb(50, 50, 50)",
          },
          ticks: {
            includeBounds: false,
            maxTicksLimit: 5,
          },
        },
      },
    }),
    []
  );

  return (
    <div className="relative mx-auto lg:h-full w-full lg:w-[99%]">
      <Line data={data} options={options} />
    </div>
  );
}
