import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Comment,
  CommentByChannelQuery,
  CreateCommentMutation,
  OnCreateCommentSubscription,
} from "../API";
import { createComment } from "../graphql/mutations";
import { commentByChannel } from "../graphql/queries";
import { onCreateComment } from "../graphql/subscriptions";

export default function Comments({
  config,
}: {
  config: {
    title?: string;
    disabled?: boolean;
    keepCommentsForS?: number;
    channel?: string;
    texts?: {
      noComment?: string;
      typeHere?: string;
      send?: string;
    };
  };
}) {
  const [input, setInput] = useState("");
  const [comments, setComments] = useState<Comment[]>([]);
  const bottomCommentElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(
    () => bottomCommentElement.current?.scrollIntoView({ behavior: "smooth" }),
    [comments]
  );

  useEffect(() => {
    const dateLimit = new Date(
      Date.now() - (config.keepCommentsForS ?? 24 * 3600) * 1000
    );

    API.graphql<GraphQLQuery<CommentByChannelQuery>>(
      graphqlOperation(commentByChannel, {
        channel: config.channel ?? "comments",
        createdAt: {
          gt: dateLimit.toISOString(),
        },
        limit: 500,
      })
    )
      .then(({ data }) =>
        data && data.commentByChannel ? data.commentByChannel.items : []
      )
      .then((items) => items.filter((v) => v != null) as Comment[])
      .then(setComments);

    const subscription = API.graphql<
      GraphQLSubscription<OnCreateCommentSubscription>
    >(
      graphqlOperation(onCreateComment, {
        filter: {
          channel: { eq: config.channel ?? "comments" },
        },
      })
    ).subscribe(({ value }) => {
      if (!value || !value.data || !value.data.onCreateComment) return;
      setComments((current) => current.concat([value.data!.onCreateComment!]));
    });

    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [config.keepCommentsForS, config.channel]);

  const onSubmit = useCallback(
    () =>
      API.graphql<GraphQLQuery<CreateCommentMutation>>(
        graphqlOperation(createComment, {
          input: {
            message: input,
            channel: config.channel ?? "comments",
          },
        })
      ).then(() => setInput("")),
    [input, config.channel]
  );

  return (
    <div className="flex flex-col h-fit w-full">
      <div className="w-full max-h-80 overflow-y-scroll border-[1px] border-gray-700 rounded-md px-3 py-1">
        {comments.length <= 0 && (
          <p>{config.texts?.noComment ?? "No comment yet."}</p>
        )}
        {comments.map((comment) => (
          <p key={comment.id}>
            {comment.message}{" "}
            <span className="text-xs text-gray-500">
              {moment(comment.createdAt).calendar()}
            </span>
          </p>
        ))}

        <div className="clear-both" ref={bottomCommentElement} />
      </div>
      <form
        className="flex text-lg mt-2"
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit();
        }}
      >
        <input
          type="text"
          disabled={config.disabled}
          className="w-full text-black rounded-l-md px-1"
          value={input}
          onChange={(ev) => setInput(ev.target.value)}
          placeholder={config.texts?.typeHere ?? "Type here."}
        />
        <button
          type="submit"
          disabled={config.disabled}
          className="px-3 rounded-r-md border-[1px] border-white"
        >
          {config.texts?.send ?? "Send"}
        </button>
      </form>
    </div>
  );
}
