import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ListDataBlobsQuery, OnUpdateDataBlobSubscription } from "../API";
import { listDataBlobs } from "../graphql/queries";
import { onUpdateDataBlob } from "../graphql/subscriptions";

export type DataBlobsType = {
  [id: string]: any;
};

export type DataContextType = {
  getValue: (searchString: string) => any;
  getNumber: (searchString: string) => number;
  getNumbersArray: (searchString: string) => number[] | undefined;
  updateTimestamp: number;
};

export const dataContext = createContext<DataContextType>({
  getValue: () => undefined,
  getNumber: () => Number.NaN,
  getNumbersArray: () => undefined,
  updateTimestamp: 0,
});

export default function DataContextProvider({
  config,
  children,
}: {
  config: string[];
  children: JSX.Element | JSX.Element[];
}) {
  const [dataBlobs, setDataBlobs] = useState<DataBlobsType>({});
  const [subscriptionRestartCounter, setSubscriptionRestartCounter] =
    useState(0);
  const [updateTimestamp, setUpdateTimestamp] = useState(0);

  useEffect(() => {
    if (!config || config.length <= 0) return () => {};

    API.graphql<GraphQLQuery<ListDataBlobsQuery>>(
      graphqlOperation(listDataBlobs, {
        filter: {
          or: config.map((id) => ({ id: { eq: id } })),
        },
      })
    )
      .then(({ data }) =>
        data && data.listDataBlobs && data.listDataBlobs.items.length > 0
          ? data.listDataBlobs.items
          : Promise.reject(new Error("No data returned"))
      )
      .then((items) =>
        items
          .filter((b) => b != null)
          .reduce((acc, v) => ({ ...acc, [v!.id]: JSON.parse(v!.blob) }), {})
      )
      .then(setDataBlobs);

    const subscription = API.graphql<
      GraphQLSubscription<OnUpdateDataBlobSubscription>
    >(
      graphqlOperation(onUpdateDataBlob, {
        filter: {
          or: config.map((id) => ({ id: { eq: id } })),
        },
      })
    ).subscribe({
      next({ value: val }) {
        if (!val || !val.data || !val.data.onUpdateDataBlob) return;
        const db = val.data.onUpdateDataBlob;
        setDataBlobs((current) => ({
          ...current,
          [db.id]: JSON.parse(db.blob),
        }));
      },
      error(err) {
        console.error(`Error on subscribe #${subscriptionRestartCounter}`, err); // eslint-disable-line no-console
        setSubscriptionRestartCounter(subscriptionRestartCounter + 1);
      },
    });

    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [config, subscriptionRestartCounter]);

  useEffect(() => setUpdateTimestamp(Date.now()), [dataBlobs]);

  const getValue = useCallback(
    (searchString: string) => {
      const [blobId, left] = searchString.split("$");
      if (!Object.prototype.hasOwnProperty.call(dataBlobs, blobId)) return null;

      const path = left.split("&");
      let ret = dataBlobs[blobId];
      path.forEach((p) => {
        if (!ret) return;
        ret = Object.prototype.hasOwnProperty.call(ret, p) ? ret[p] : null;
      });

      return ret;
    },
    [dataBlobs]
  );

  const getNumber = useCallback(
    (searchString: string) => Number.parseFloat(getValue(searchString)),
    [getValue]
  );

  const getNumbersArray = useCallback(
    (searchString: string) => {
      const values = getValue(searchString);
      if (!values || !Array.isArray(values)) return undefined;

      return values.map(Number.parseFloat);
    },
    [getValue]
  );

  const value = useMemo(
    () => ({
      getValue,
      getNumber,
      getNumbersArray,
      updateTimestamp,
    }),
    [getValue, getNumber, getNumbersArray, updateTimestamp]
  );
  return <dataContext.Provider value={value}>{children}</dataContext.Provider>;
}
