import React, { useMemo, useState } from "react";
import { Tab } from "@headlessui/react";
import Format from "./Format";

export type TabElement = { title: string; element: JSX.Element };
export default function Tabs({
  defaultTab,
  children,
  className,
  selectedTab,
  setSelectedTab,
}: {
  defaultTab?: number;
  children: (
    tab: (title: string, element: JSX.Element) => TabElement
  ) => TabElement | undefined | (TabElement | undefined)[];
  className?: string;
  selectedTab?: number;
  setSelectedTab?: (index: number) => void;
}) {
  const [fallbackSelectedTab, fallbackSetSelectedTab] = useState(
    defaultTab ?? 0
  );

  const list = useMemo(() => {
    const a = children((title, element) => ({
      title,
      element,
    }));

    return (Array.isArray(a) ? a : [a]).filter((e): e is TabElement => !!e);
  }, [children]);

  return (
    <Tab.Group
      selectedIndex={selectedTab ?? fallbackSelectedTab}
      onChange={(
        ev: number | React.FormEvent<HTMLDivElement> // Pointless hack to make TS happy
      ) => (setSelectedTab ?? fallbackSetSelectedTab)(ev as number)}
      as="div"
      className={["flex flex-col", className].join(" ")}
    >
      <Tab.List>
        {list.map(({ title, element }) => (
          <Tab
            key={element.key}
            className={({ selected }) =>
              [
                "mr-1 rounded-t-md px-3 py-1 text-sm",
                selected ? "bg-primary" : "bg-highlight",
              ].join(" ")
            }
          >
            <Format str={title} />
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="flex-grow min-h-0">
        {list.map(({ element }) => (
          <Tab.Panel
            className="h-full bg-primary rounded-b-md rounded-tr-md p-2"
            key={element.key}
          >
            {element}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

Tabs.defaultProps = {
  defaultTab: undefined,
  className: undefined,
  selectedTab: undefined,
  setSelectedTab: undefined,
};
