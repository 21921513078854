import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { AuthContextType, authContext, AuthState, cognitoPool } from "./auth";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import NewPassword from "./NewPassword";

export default function AuthContextProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [authState, setAuthState] = useState(AuthState.LOGIN);

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(
    cognitoPool.getCurrentUser()
  );
  const [userSession, setUserSession] = useState<
    CognitoUserSession | undefined
  >();

  useEffect(() => {
    if (cognitoUser) {
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err || !session || !session.isValid()) return;
          setUserSession(session);
        }
      );
    }
  }, [cognitoUser]);

  const signOut = useCallback(() => {
    if (!cognitoUser) return;
    cognitoUser.signOut();
    setUserSession(undefined);
    setCognitoUser(null);
  }, [cognitoUser]);

  const value: AuthContextType = useMemo(
    () => ({
      userSession,
      setUserSession,

      signOut,
    }),
    [userSession, signOut]
  );

  return (
    <authContext.Provider value={value}>
      {userSession ? (
        children
      ) : (
        <div className="h-full flex">
          <div className="border-2 border-white bg-primary w-5/6 lg:w-1/3 mx-auto my-auto">
            {authState === AuthState.LOGIN && (
              <Login
                setAuthState={setAuthState}
                setCognitoUser={setCognitoUser}
              />
            )}
            {authState === AuthState.NEWPASSWORDREQUIRED && (
              <NewPassword
                setAuthState={setAuthState}
                cognitoUser={cognitoUser ?? undefined}
              />
            )}
            {authState === AuthState.FORGOTPASSWORD && (
              <ForgotPassword setAuthState={setAuthState} />
            )}
          </div>
        </div>
      )}
    </authContext.Provider>
  );
}
