import { CognitoUser } from "amazon-cognito-identity-js";
import React, { useCallback, useId, useState } from "react";
import { AuthState, cognitoPool } from "./auth";
import AuthErrorBox from "./AuthErrorBox";

export default function ForgotPassword({
  setAuthState,
}: {
  setAuthState: (state: AuthState) => void;
}) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const [done, setDone] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [authError, setAuthError] = useState("");

  const emailId = useId();
  const codeId = useId();
  const passwordId = useId();

  const sendCode = useCallback(() => {
    setProcessing(true);
    setAuthError("");

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => setProcessing(false),
      onFailure: (err: any) => {
        setAuthError(err.message);
        setProcessing(false);
      },
      inputVerificationCode: () => {
        setCodeSent(true);
        setProcessing(false);
      },
    });
  }, [email]);

  const confirmPassword = useCallback(() => {
    setProcessing(true);
    setAuthError("");

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });

    cognitoUser.confirmPassword(code, password, {
      onSuccess() {
        setDone(true);
        setProcessing(false);
      },
      onFailure(err) {
        setAuthError(err.message);
        setProcessing(false);
      },
    });
  }, [code, password, email]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!codeSent) {
          sendCode();
        } else {
          confirmPassword();
        }
      }}
    >
      <h1 className="text-4xl pt-5 px-5">Reset Password</h1>
      <p className="mx-5 mt-2">
        {done && "Your password has been reset. You can now login."}
        {!done &&
          (codeSent
            ? "A code has been sent to your inbox."
            : "Please enter your email address. A code will be sent to your inbox to reset your password.")}
      </p>
      {authError && <AuthErrorBox message={authError} />}

      {!done && (
        <div className="m-5 text-lg">
          {!codeSent ? (
            <label htmlFor={emailId}>
              Email
              <input
                type="email"
                id={emailId}
                className="w-full text-black p-2"
                onChange={(ev) => setEmail(ev.target.value)}
                value={email}
                disabled={processing}
              />
            </label>
          ) : (
            <>
              <label htmlFor={codeId}>
                Code
                <input
                  type="text"
                  id={codeId}
                  className="w-full text-black p-2"
                  onChange={(ev) => setCode(ev.target.value)}
                  value={code}
                  disabled={processing}
                />
              </label>
              <label htmlFor={passwordId}>
                New Password
                <input
                  type="password"
                  id={passwordId}
                  className="w-full text-black p-2"
                  onChange={(ev) => setPassword(ev.target.value)}
                  value={password}
                  disabled={processing}
                />
              </label>
            </>
          )}
        </div>
      )}

      <div className="w-full flex my-10">
        {done && (
          <button
            type="button"
            className="bg-gray-300 text-black m-auto px-5 text-lg py-2 rounded-md hover:bg-gray-400 active:bg-gray-500 disabled:bg-neutral-600"
            onClick={() => setAuthState(AuthState.LOGIN)}
          >
            Back to Login
          </button>
        )}
        {!done && (
          <button
            type="submit"
            className="bg-gray-300 text-black m-auto px-5 text-lg py-2 rounded-md hover:bg-gray-400 active:bg-gray-500 disabled:bg-neutral-600"
            disabled={
              processing ||
              (!codeSent && !email) ||
              (codeSent && (!code || !password))
            }
          >
            {!codeSent ? "Reset password" : "Submit"}
          </button>
        )}
      </div>
    </form>
  );
}
