import React, { useContext } from "react";
import Format, { formatter } from "./Format";
import { dataContext } from "./DataContextProvider";

export default function Text({
  config,
  className,
}: {
  config: {
    text?: string;
    textColor?: string;
    backgroundColor?: string;
  };
  className?: string;
}) {
  const { getValue } = useContext(dataContext);

  return (
    <p
      className={[
        "bg-primary text-white rounded-t-md px-3 py-1 rounded-md",
        className,
      ].join(" ")}
      style={{
        color: config.textColor
          ? formatter(config.textColor, getValue)
          : undefined,
        backgroundColor: config.backgroundColor
          ? formatter(config.backgroundColor, getValue)
          : undefined,
      }}
    >
      <Format str={config.text} />
    </p>
  );
}

Text.defaultProps = {
  className: undefined,
};
