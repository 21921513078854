import { GraphQLSubscription } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { OnUpdateAppParametersSubscription } from "../API";
import { onUpdateAppParameters } from "../graphql/subscriptions";

const RESTART_ID = "restart";
const RESTART_TIMEOUT_S = 24 * 60 * 60;

export default function RestartComponent() {
  const [subscriptionRestartCounter, setSubscriptionRestartCounter] =
    useState(0);

  useEffect(() => {
    const t = setTimeout(
      () => window.location.reload(),
      RESTART_TIMEOUT_S * 1000
    );
    return () => clearTimeout(t);
  }, []);

  useEffect(() => {
    const subscription = API.graphql<
      GraphQLSubscription<OnUpdateAppParametersSubscription>
    >(graphqlOperation(onUpdateAppParameters)).subscribe({
      next({ value: val }) {
        if (
          val &&
          val.data &&
          val.data.onUpdateAppParameters &&
          val.data.onUpdateAppParameters.id === RESTART_ID
        )
          window.location.reload();
      },
      error() {
        setSubscriptionRestartCounter(subscriptionRestartCounter + 1);
      },
    });

    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [subscriptionRestartCounter]);

  return null;
}
