import {
  deepmergeCustom,
  type DeepMergeLeaf,
  type DeepMergeMergeFunctionsURIs,
} from "deepmerge-ts";

const deepmergeIgnoreUndefined = deepmergeCustom<{
  DeepMergeOthersURI: "DeepMergeLeafNoUndefinedOverrideURI";
}>({
  mergeOthers: (values, utils) =>
    utils.defaultMergeFunctions.mergeOthers(
      values.filter((v) => v !== undefined)
    ),
});
export default deepmergeIgnoreUndefined;

declare module "deepmerge-ts" {
  interface DeepMergeMergeFunctionURItoKind<
    Ts extends ReadonlyArray<unknown>,
    MF extends DeepMergeMergeFunctionsURIs, // eslint-disable-line @typescript-eslint/no-unused-vars
    M // eslint-disable-line @typescript-eslint/no-unused-vars
  > {
    readonly DeepMergeLeafNoUndefinedOverrideURI: DeepMergeLeafNoUndefinedOverride<Ts>;
  }
}

type DeepMergeLeafNoUndefinedOverride<Ts extends ReadonlyArray<unknown>> =
  DeepMergeLeaf<FilterOutUnderfined<Ts>>;

type FilterOutUnderfined<T extends ReadonlyArray<unknown>> =
  FilterOutUnderfinedHelper<T, []>;

type FilterOutUnderfinedHelper<
  T extends ReadonlyArray<unknown>,
  Acc extends ReadonlyArray<unknown>
> = T extends readonly []
  ? Acc
  : T extends readonly [infer Head, ...infer Rest]
  ? Head extends undefined
    ? FilterOutUnderfinedHelper<Rest, Acc>
    : FilterOutUnderfinedHelper<Rest, [...Acc, Head]>
  : T;
