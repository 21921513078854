import React, { createContext, useCallback, useMemo, useState } from "react";

export type GraphRange = {
  max: number;
  min: number;
};

export type GraphRangeContextType = {
  getRange: (id: number, timestamp?: number) => GraphRange | undefined;
  addRange: (id: number, range: GraphRange, timestamp?: number) => void;
  addMinRange: (id: number, range: number) => void;
};

export const graphRangeContext = createContext<GraphRangeContextType>({
  getRange: () => undefined,
  addRange: () => {},
  addMinRange: () => {},
});

export default function GraphRangeContextProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[] | string;
}) {
  const [ranges, setRanges] = useState<{
    [id: number]: { max: number; min: number; timestamp: number };
  }>({});
  const [minRanges, setMinRanges] = useState<{
    [id: number]: number;
  }>({});

  const getRange = useCallback(
    (id: number, timestamp?: number) => {
      const r = ranges[id];
      if (!r) return undefined;
      if (typeof timestamp !== "undefined" && timestamp !== r.timestamp)
        return undefined;

      const delta = (r.max - r.min) * 0.1;

      const min = r.min - delta;
      const max = r.max + delta;

      const minRange = minRanges[id];
      if (minRange && max - min < minRange) {
        const missingRange = minRange - (max - min);
        return {
          min: min - missingRange / 2,
          max: max + missingRange / 2,
        };
      }

      return {
        min,
        max,
      };
    },
    [ranges, minRanges]
  );
  const addRange = useCallback(
    (id: number, range: GraphRange, timestamp: number = 0) => {
      setRanges((currentRanges) => {
        const old = currentRanges[id];
        return {
          ...currentRanges,
          [id]: {
            max:
              old && timestamp === old.timestamp
                ? Math.max(old.max, range.max)
                : range.max,
            min:
              old && timestamp === old.timestamp
                ? Math.min(old.min, range.min)
                : range.min,
            timestamp,
          },
        };
      });
    },
    []
  );
  const addMinRange = useCallback((id: number, range: number) => {
    setMinRanges((current) => {
      const old = current[id];
      return {
        ...current,
        [id]: old ? Math.max(old, range) : range,
      };
    });
  }, []);

  const value = useMemo(
    () => ({
      getRange,
      addRange,
      addMinRange,
    }),
    [getRange, addRange, addMinRange]
  );

  return (
    <graphRangeContext.Provider value={value}>
      {children}
    </graphRangeContext.Provider>
  );
}
