import { CognitoUser } from "amazon-cognito-identity-js";
import React, { useCallback, useContext, useId, useState } from "react";
import { authContext, AuthState } from "./auth";
import AuthErrorBox from "./AuthErrorBox";

export default function NewPassword({
  setAuthState,
  cognitoUser,
}: {
  setAuthState: (state: AuthState) => void;
  cognitoUser?: CognitoUser;
}) {
  const { setUserSession } = useContext(authContext)!;
  const [password, setPassword] = useState("");

  const [processing, setProcessing] = useState(false);
  const [authError, setAuthError] = useState("");

  const passwordId = useId();

  const onSubmit = useCallback(() => {
    if (!cognitoUser) setAuthState(AuthState.LOGIN);
    setProcessing(true);

    cognitoUser!.completeNewPasswordChallenge(
      password,
      {},
      {
        onSuccess: (session) => {
          setUserSession(session);
          setProcessing(false);
        },
        onFailure: (err: any) => {
          setAuthError(err.message);
          setProcessing(false);
        },
      }
    );
  }, [password, setUserSession, setAuthState, cognitoUser]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <h1 className="text-4xl pt-5 px-5">Set Password</h1>
      <p className="mx-5 mt-2">
        Your temporary password could only be used once. Please set a new
        permanent password.
      </p>
      {authError && <AuthErrorBox message={authError} />}

      <div className="m-5 text-lg">
        <label htmlFor={passwordId}>
          New Password
          <input
            type="password"
            id={passwordId}
            className="w-full text-black p-2"
            onChange={(ev) => setPassword(ev.target.value)}
            value={password}
            disabled={processing}
          />
        </label>
      </div>

      <div className="w-full flex my-10">
        <button
          type="submit"
          className="bg-gray-300 text-black m-auto px-5 text-lg py-2 rounded-md hover:bg-gray-400 active:bg-gray-500 disabled:bg-neutral-600"
          disabled={!password || processing}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

NewPassword.defaultProps = {
  cognitoUser: undefined,
};
