import {
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { createContext } from "react";
import awsconfig from "../../aws-exports";

export const enum AuthState {
  LOGIN,
  NEWPASSWORDREQUIRED,
  FORGOTPASSWORD,
}

export type AuthContextType = {
  userSession?: CognitoUserSession;
  setUserSession: (userSession: CognitoUserSession) => void;

  signOut: () => void;
};

export const authContext = createContext<AuthContextType | undefined>(
  undefined
);

export const cognitoPool = new CognitoUserPool({
  UserPoolId: awsconfig.aws_user_pools_id,
  ClientId: awsconfig.aws_user_pools_web_client_id,
});
