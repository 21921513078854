/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        message
        createdAt
        channel
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentByChannel = /* GraphQL */ `
  query CommentByChannel(
    $channel: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentByChannel(
      channel: $channel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        createdAt
        channel
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataBlob = /* GraphQL */ `
  query GetDataBlob($id: ID!) {
    getDataBlob(id: $id) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataBlobs = /* GraphQL */ `
  query ListDataBlobs(
    $filter: ModelDataBlobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataBlobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blob
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppParameters = /* GraphQL */ `
  query GetAppParameters($id: ID!) {
    getAppParameters(id: $id) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppParameters = /* GraphQL */ `
  query ListAppParameters(
    $filter: ModelAppParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parameter
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
