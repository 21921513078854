/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataBlob = /* GraphQL */ `
  subscription OnCreateDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
    onCreateDataBlob(filter: $filter) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataBlob = /* GraphQL */ `
  subscription OnUpdateDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
    onUpdateDataBlob(filter: $filter) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataBlob = /* GraphQL */ `
  subscription OnDeleteDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
    onDeleteDataBlob(filter: $filter) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAppParameters = /* GraphQL */ `
  subscription OnCreateAppParameters(
    $filter: ModelSubscriptionAppParametersFilterInput
  ) {
    onCreateAppParameters(filter: $filter) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAppParameters = /* GraphQL */ `
  subscription OnUpdateAppParameters(
    $filter: ModelSubscriptionAppParametersFilterInput
  ) {
    onUpdateAppParameters(filter: $filter) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAppParameters = /* GraphQL */ `
  subscription OnDeleteAppParameters(
    $filter: ModelSubscriptionAppParametersFilterInput
  ) {
    onDeleteAppParameters(filter: $filter) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
