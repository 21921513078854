import React, { useContext } from "react";
import { authContext } from "./auth";

export default function LogoutButton({
  className,
  children,
}: {
  className?: string;
  children: JSX.Element | JSX.Element[] | string;
}) {
  const { signOut } = useContext(authContext)!;

  return (
    <button type="button" className={className} onClick={signOut}>
      {children}
    </button>
  );
}

LogoutButton.defaultProps = {
  className: undefined,
};
