import React from "react";
import isValidNumber from "../../utils/isValidNumber";
import BacktestingGraph from "./BacktestingGraph";
import Graph from "./Graph";

export default function Graphs({
  graphs,
  onElementSelected,
}: {
  graphs?: { id: string; width?: number; type?: string }[];
  onElementSelected: (val: [string, number] | undefined) => void;
}) {
  let defaultWidth = 100;

  if (graphs) {
    const [sum, fixedCount] = graphs
      .map((b) => b.width)
      .filter(isValidNumber)
      .reduce((p, c) => [p[0] + c, p[1] + 1], [0, 0]);

    defaultWidth = (100 - sum) / (graphs.length - fixedCount);
  }

  return (
    <div className="flex justify-around w-full h-full flex-col lg:flex-row lg:space-x-2 flex-wrap lg:flex-nowrap">
      {graphs &&
        graphs.map((graph) => (
          <div
            key={graph.id}
            className="-lg:!w-full lg:h-full"
            style={{ width: `${graph.width ?? defaultWidth}%` }}
          >
            {graph.type === "backtesting" ? (
              <BacktestingGraph config={graph as any} />
            ) : (
              <Graph
                config={graph as any}
                onElementSelected={onElementSelected}
              />
            )}
          </div>
        ))}
    </div>
  );
}

Graphs.defaultProps = {
  graphs: undefined,
};
