/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
  }
`;
export const createDataBlob = /* GraphQL */ `
  mutation CreateDataBlob(
    $input: CreateDataBlobInput!
    $condition: ModelDataBlobConditionInput
  ) {
    createDataBlob(input: $input, condition: $condition) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataBlob = /* GraphQL */ `
  mutation UpdateDataBlob(
    $input: UpdateDataBlobInput!
    $condition: ModelDataBlobConditionInput
  ) {
    updateDataBlob(input: $input, condition: $condition) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataBlob = /* GraphQL */ `
  mutation DeleteDataBlob(
    $input: DeleteDataBlobInput!
    $condition: ModelDataBlobConditionInput
  ) {
    deleteDataBlob(input: $input, condition: $condition) {
      id
      blob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppParameters = /* GraphQL */ `
  mutation CreateAppParameters(
    $input: CreateAppParametersInput!
    $condition: ModelAppParametersConditionInput
  ) {
    createAppParameters(input: $input, condition: $condition) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppParameters = /* GraphQL */ `
  mutation UpdateAppParameters(
    $input: UpdateAppParametersInput!
    $condition: ModelAppParametersConditionInput
  ) {
    updateAppParameters(input: $input, condition: $condition) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppParameters = /* GraphQL */ `
  mutation DeleteAppParameters(
    $input: DeleteAppParametersInput!
    $condition: ModelAppParametersConditionInput
  ) {
    deleteAppParameters(input: $input, condition: $condition) {
      id
      parameter
      createdAt
      updatedAt
      __typename
    }
  }
`;
