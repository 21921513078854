import React from "react";

export default function AuthErrorBox({
  message,
  className,
}: {
  message: string;
  className?: string;
}) {
  return (
    <p className={["border-red-500 border-2 mx-5 p-2", className].join(" ")}>
      {message}
    </p>
  );
}

AuthErrorBox.defaultProps = { className: undefined };
