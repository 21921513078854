import React from "react";
import LogoutButton from "./auth/LogoutButton";

import logo from "../assets/logo.jpeg";
import Format from "./Format";

export default function Header({
  className,
  config,
}: {
  className?: string;
  config?: {
    headerText?: string;
    logoutText?: string;
  };
}) {
  return (
    <div
      className={[
        "w-full py-2 lg:h-12 bg-primary flex px-2 justify-between flex-wrap relative",
        className,
      ].join(" ")}
    >
      <div className="lg:h-full lg:p-1 w-1/4 lg:w-auto flex">
        <img src={logo} alt="LeonX Logo" className="lg:h-full my-auto" />
      </div>

      <div className="w-3/4 lg:w-auto flex">
        {config && config.headerText && (
          <p className="my-auto mx-5 text-xl">
            <Format str={config.headerText} />
          </p>
        )}

        <LogoutButton className="ml-auto border-[1px] border-white px-2 py-1 my-auto hover:bg-neutral-500">
          {(config && config.logoutText) ?? "Logout"}
        </LogoutButton>
      </div>
    </div>
  );
}

Header.defaultProps = { className: undefined, config: {} };
