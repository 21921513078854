import React from "react";
import { dataContext } from "./DataContextProvider";

export function formatter(
  str: string | undefined,
  getValue: (search: string) => any
) {
  if (!str) return "";

  const matches = str.match("{[^{}]*}");
  if (!matches) return str;

  return matches.reduce(
    (prev, current) =>
      prev.replace(current, getValue(current.replaceAll(/[{}]/gi, "")) ?? ""),
    str
  );
}

export default function Format({ str }: { str: string | undefined }) {
  return (
    <dataContext.Consumer>
      {({ getValue }) => formatter(str, getValue)}
    </dataContext.Consumer>
  );
}
